import firebaseApp from '../firebase-app';
import { getFirestore, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Settings } from '@/app/types/database/Settings';
import { FolderMetadata } from '@/app/types/database/FolderMetadata';

const updateTheme = async (theme: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'settings', auth.currentUser.uid),
    {
      theme
    },
    { merge: true }
  );
};

const updateGeneratorBehavior = async (generatorBehavior: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'settings', auth.currentUser.uid),
    {
      generatorBehavior
    },
    { merge: true }
  );
};

const updateFlashcardLanguage = async (flashcardLanguage: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'settings', auth.currentUser.uid),
    {
      flashcardLanguage
    },
    { merge: true }
  );
};

const setFoldersEnabled = async (enabled: boolean) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'settings', auth.currentUser.uid),
    {
      foldersEnabled: enabled
    },
    { merge: true }
  );
};

const setDebugModeEnabled = async (enabled: boolean) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'settings', auth.currentUser.uid),
    {
      googleAnalyticsDebugMode: enabled
    } as Settings,
    { merge: true }
  );
};

const setFolders = async (folders: FolderMetadata[]) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'settings', auth.currentUser.uid),
    {
      folders
    },
    { merge: true }
  );
};

const setGoogleAnalyticsClientId = async (clientId: string) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const currentUser = auth.currentUser;

  if (currentUser) {
    await setDoc(
      doc(db, 'settings', auth.currentUser.uid),
      {
        googleAnalyticsClientId: clientId
      } as Settings,
      { merge: true }
    );
  }
};

const setSelectedFolder = async (selectedFolder: string | null) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  await setDoc(
    doc(db, 'settings', auth.currentUser.uid),
    {
      selectedFolder
    },
    { merge: true }
  );
};

const subscribeToSettings = (onChange: (settings: Settings) => void) => {
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const unsub = onSnapshot(doc(db, 'settings', auth.currentUser.uid), (doc) => {
    const settings = (doc.data() as Settings) || ({} as Settings);

    if (!settings.theme) {
      settings.theme = 'limbiks';
    }

    if (!settings.generatorBehavior) {
      settings.generatorBehavior = 'Default';
    }

    if (!settings.flashcardLanguage) {
      settings.flashcardLanguage = 'x';
    }

    settings.foldersEnabled = true;

    if (!settings.folders) {
      settings.folders = [
        {
          id: null,
          name: 'My Decks'
        }
      ];
    }

    if (!settings.selectedFolder) {
      settings.selectedFolder = null;
    }

    if (!settings.googleAnalyticsClientId) {
      settings.googleAnalyticsClientId = null;
    }

    onChange(settings);
  });

  return unsub;
};

export default {
  updateTheme,
  updateGeneratorBehavior,
  setFoldersEnabled,
  setDebugModeEnabled,
  setFolders,
  setSelectedFolder,
  subscribeToSettings,
  setGoogleAnalyticsClientId,
  updateFlashcardLanguage
};
