export const supportedLanguages = [
  { value: 'x', name: 'Auto-Detect' },
  { value: 'en', name: 'English' },
  { value: 'ca', name: 'Catalan' },
  { value: 'hr', name: 'Croatian' },
  { value: 'da', name: 'Danish' },
  { value: 'nl', name: 'Dutch' },
  { value: 'fi', name: 'Finnish' },
  { value: 'fr', name: 'French' },
  { value: 'de', name: 'German' },
  { value: 'el', name: 'Greek' },
  { value: 'it', name: 'Italian' },
  { value: 'ja', name: 'Japanese' },
  { value: 'ko', name: 'Korean' },
  { value: 'lt', name: 'Lithuanian' },
  { value: 'mk', name: 'Macedonian' },
  { value: 'pl', name: 'Polish' },
  { value: 'pt', name: 'Portuguese' },
  { value: 'ro', name: 'Romanian' },
  { value: 'ru', name: 'Russian' },
  { value: 'es', name: 'Spanish' },
  { value: 'sv', name: 'Swedish' },
  { value: 'uk', name: 'Ukrainian' }
];
