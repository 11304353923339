import { useRouter } from 'next/router';
import React from 'react';
import { ThemeSelector } from './theme-selector/ThemeSelector';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { Select } from '@headlessui/react';
import { supportedLanguages } from '@/app/constants/SupportedLanguages';

export default function Settings({ onClose }) {
  const router = useRouter();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const { user } = useAuth();

  const {
    flashcardLanguage,
    toggleDebugMode,
    generatorBehavior,
    updateGeneratorBehavior,
    updateFlashcardLanguage,
    debugModeEnabled
  } = useSettings();

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: 'left'
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left'
    })
  };

  return (
    <>
      <div className="mb-2 w-full text-xl font-bold">Settings</div>
      {/* <div className="form-control mt-1 w-44">
        <label className="label cursor-pointer">
          <span className="label-text font-bold text-black">
            Enable Folders
          </span>
          <input
            type="checkbox"
            onChange={toggleFoldersEnabled}
            className="toggle toggle-secondary"
            checked={foldersEnabled}
          />
        </label>
      </div>
      <div className="mt-3 w-full text-xs">
        Use folders for enhanced organization of your decks. All existing decks
        will be placed in a folder called 'My Decks' and more folders can be
        created. Right click (or long press on mobile) on a deck to move it to a
        new folder.
      </div> */}
      <div className="mt-7 rounded text-sm text-black">
        <div className="mb-2 w-full font-bold ">Flaschard Density</div>
        <div className="form-control mt-3 flex">
          <label
            className={`label cursor-pointer rounded pl-2 ${
              generatorBehavior === 'Detailed'
                ? 'bg-neutral text-neutral-content'
                : ''
            }`}
          >
            <span className="text-sm">High</span>
            <input
              type="radio"
              name={'generatorBehavior'}
              className="checked:bg-red-500 radio-secondary radio-sm"
              onChange={() => {
                trackEvent(analyticsInstance, 'settings_click_density_high');
                updateGeneratorBehavior('Detailed');
              }}
              checked={generatorBehavior === 'Detailed'}
            />
          </label>
          <div className="flex-1"></div>
        </div>
        <div className="form-control flex">
          <label
            className={`label cursor-pointer rounded pl-2 ${
              generatorBehavior === 'Default'
                ? 'bg-neutral text-neutral-content'
                : ''
            }`}
          >
            <span className="text-sm">Medium</span>
            <input
              type="radio"
              name={'generatorBehavior'}
              className="checked:bg-red-500 radio-secondary radio-sm"
              onChange={() => {
                trackEvent(analyticsInstance, 'settings_click_density_medium');
                updateGeneratorBehavior('Default');
              }}
              checked={generatorBehavior === 'Default'}
            />
          </label>
          <div className="flex-1"></div>
        </div>
        <div className="form-control flex">
          <label
            className={`label cursor-pointer rounded pl-2 ${
              generatorBehavior === 'Sparse'
                ? 'bg-neutral text-neutral-content'
                : ''
            }`}
          >
            <span className="text-sm">Low</span>
            <input
              type="radio"
              name={'generatorBehavior'}
              className="checked:bg-red-500 radio-secondary radio-sm"
              onChange={() => {
                trackEvent(analyticsInstance, 'settings_click_density_low');
                updateGeneratorBehavior('Sparse');
              }}
              checked={generatorBehavior === 'Sparse'}
            />
          </label>
          <div className="flex-1"></div>
        </div>

        <div className="mt-3 w-full text-xs">
          Density controls the number of flashcards created by the generator for
          each page/slide. High density tells Limbiks to make as many flashcards
          as possible from the content uploaded. If Limbiks is creating too many
          flashcards, try changing density to low.
        </div>
      </div>

      <div className="mt-7 rounded text-sm text-black">
        <div className="mb-2 w-full font-bold ">Flashcard Language</div>

        <div className="form-control mt-3 flex">
          <Select
            name="status"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
            aria-label="Project status"
            value={flashcardLanguage}
            onChange={(e) => {
              updateFlashcardLanguage(e.target.value);
            }}
          >
            {supportedLanguages.map((language) => (
              <option value={language.value}>{language.name}</option>
            ))}
          </Select>
          <div className="flex-1"></div>
        </div>
        <div className="mt-3 w-full text-xs">
          Select Auto-Detect to generate flashcards in the same language as the
          uplaoded file. Select a specific lanaguage to always generate
          flashcards in the same language, regardless of the language of the
          uploaded file.
        </div>
      </div>

      {/* <div className="form-control mt-1">
        <label className="label cursor-pointer">
          <span className="label-text font-bold text-black">
            Show Cards Due Indicator
          </span>
          <input
            type="checkbox"
            onChange={toggleFoldersEnabled}
            className="toggle toggle-secondary"
            checked={foldersEnabled}
          />
        </label>
      </div>
      <div className="mt-3 w-full text-xs">
        By default, if there are any cards due for memorization, the memorize
        button will display the total number due. If you would prefer not to see
        the number of cards due all the time, you can disable this toggle here.
      </div> */}
      <div className="-mb-2 mt-8 w-full text-sm font-bold">Theme</div>
      <ThemeSelector />
      {user && user.email.startsWith('limbiks.root') && (
        <>
          <div className="-mb-2 mt-8 w-full text-sm font-bold">
            INTERNAL ONLY
          </div>
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text text-black">
                Analytics Debug Mode
              </span>
              <input
                onChange={toggleDebugMode}
                checked={debugModeEnabled}
                type="checkbox"
                className="toggle"
              />
            </label>
          </div>
        </>
      )}
      <div className="modal-action">
        <div>
          <label htmlFor="my-modal-2" onClick={onClose} className="btn">
            Close
          </label>
        </div>
      </div>
    </>
  );
}
