import React, { createContext, useEffect, useState } from 'react';
import { useAuth } from '../AuthContext/useAuth';
import SettingsDB from '@/app/database/settings/SettingsDB';
import { Settings } from '@/app/types/database/Settings';
import { FolderMetadata } from '@/app/types/database/FolderMetadata';
import { v4 as uuidv4 } from 'uuid';
import { getGoogleAnalyticsClientId } from '@/app/utils/googleUtils';

interface ISettingsContext {
  theme: string;
  folders: FolderMetadata[];
  googleAnalyticsClientId: string;
  debugModeEnabled: boolean;
  selectedFolder: string | null;
  generatorBehavior: 'Default' | 'Detailed' | 'Sparse';
  flashcardLanguage: string;
  foldersEnabled: boolean;
  updateTheme: (theme: string) => Promise<void>;
  updateGeneratorBehavior: (
    mode: 'Default' | 'Detailed' | 'Sparse'
  ) => Promise<void>;
  updateFlashcardLanguage: (languageCode: string) => Promise<void>;
  selectFolder: (id: string) => null;
  toggleFoldersEnabled: () => Promise<void>;
  updateFolders: (folders: FolderMetadata[]) => null;
  createFolder: (name: string) => null;
  renameFolder: (id: string, name: string) => null;
  deleteFolder: (id: string) => null;
  toggleDebugMode: () => null;
}

export const SettingsContext = createContext<ISettingsContext>(null);

export const SettingsProvider = (props) => {
  const [settings, setSettings] = useState<Settings>({
    theme: 'limbiks',
    generatorBehavior: 'Default',
    flashcardLanguage: 'x',
    foldersEnabled: false,
    folders: [],
    selectedFolder: null,
    googleAnalyticsClientId: null
  });

  const [folders, setFolders] = useState<FolderMetadata[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [alreadyAttemptedClientId, setAlreadyAttemptedClientId] =
    useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (user && !user.isAnonymous) {
      const unsubscribe = SettingsDB.subscribeToSettings((settings) => {
        setSettings(settings);

        // Check if we should save google analytics client id
        const currentClientId = getGoogleAnalyticsClientId();

        if (
          !alreadyAttemptedClientId &&
          currentClientId &&
          settings.googleAnalyticsClientId !== currentClientId
        ) {
          SettingsDB.setGoogleAnalyticsClientId(currentClientId);
        }

        // Use this boolean to prevent a potential infinite loop trying to set client id
        setAlreadyAttemptedClientId(true);
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    setFolders(settings.folders);
    setSelectedFolder(settings.selectedFolder);
  }, [settings]);

  useEffect(() => {
    if (!user || user.isAnonymous) {
      setSettings({
        theme: 'limbiks',
        generatorBehavior: 'Default',
        flashcardLanguage: 'x',
        foldersEnabled: false,
        folders: [],
        selectedFolder: null,
        googleAnalyticsClientId: null
      });
    }
  }, [user]);

  // initially set the theme and "listen" for changes to apply them to the HTML tag
  React.useEffect(() => {
    document.querySelector('html').setAttribute('data-theme', settings.theme);
  }, [settings]);

  const toggleDebugMode = async () => {
    await SettingsDB.setDebugModeEnabled(!settings.googleAnalyticsDebugMode);
  };

  const updateTheme = async (theme: string) => {
    await SettingsDB.updateTheme(theme);
  };

  const updateGeneratorBehavior = async (
    mode: 'Default' | 'Detailed' | 'Sparse'
  ) => {
    await SettingsDB.updateGeneratorBehavior(mode);
  };

  const updateFlashcardLanguage = async (languageCode: string) => {
    await SettingsDB.updateFlashcardLanguage(languageCode);
  };

  const toggleFoldersEnabled = () => {
    SettingsDB.setFoldersEnabled(!settings.foldersEnabled);

    if (!folders.some((folder) => folder.id == null)) {
      const updatedFolders = [{ id: null, name: 'My Decks' }, ...folders];
      updateFolders(updatedFolders);
    }
  };

  const selectFolder = (id: string) => {
    setSelectedFolder(id);
    SettingsDB.setSelectedFolder(id);
  };

  const updateFolders = (folders: FolderMetadata[]) => {
    setFolders(folders);
    SettingsDB.setFolders(folders);
  };

  const createFolder = async (name: string) => {
    const newFolder: FolderMetadata = {
      id: uuidv4(),
      name
    };

    const updatedFolders = [...folders];

    const selectedFolderIndex = updatedFolders.findIndex(
      (f) => f.id == selectedFolder
    );

    updatedFolders.splice(selectedFolderIndex + 1, 0, newFolder);

    setFolders(updatedFolders);
    await SettingsDB.setFolders(updatedFolders);

    await selectFolder(newFolder.id);
  };

  const renameFolder = async (id: string, name: string) => {
    const updatedFolders = folders.map((folder) => {
      if (folder.id === id) {
        return { ...folder, name };
      }

      return folder;
    });

    setFolders(updatedFolders);
    await SettingsDB.setFolders(updatedFolders);
  };

  const deleteFolder = async (id: string) => {
    const deletedFolderIndex = folders.findIndex((folder) => folder.id == id);

    const updatedFolders = folders.filter((folder) => folder.id != id);

    if (updatedFolders.length === 0) {
      window.alert(
        'At least 1 folder is required.\n\nPlease create a new folder before deleting this one.'
      );

      return;
    }

    setFolders(folders);

    await SettingsDB.setFolders(updatedFolders);

    if (deletedFolderIndex < updateFolders.length) {
      await selectFolder(updatedFolders[deletedFolderIndex].id);
    } else if (deletedFolderIndex - 1 >= 0) {
      await selectFolder(updatedFolders[deletedFolderIndex - 1].id);
    } else {
      await selectFolder(updatedFolders[0].id);
    }
  };

  const value = {
    theme: settings.theme,
    generatorBehavior: settings.generatorBehavior,
    flashcardLanguage: settings.flashcardLanguage,
    googleAnalyticsClientId: settings.googleAnalyticsClientId,
    debugModeEnabled: !!settings.googleAnalyticsDebugMode,
    folders,
    selectedFolder,
    foldersEnabled: settings.foldersEnabled,
    updateTheme,
    updateGeneratorBehavior,
    updateFlashcardLanguage,
    selectFolder,
    renameFolder,
    updateFolders,
    createFolder,
    toggleDebugMode,
    deleteFolder,
    toggleFoldersEnabled
  };

  return <SettingsContext.Provider value={value} {...props} />;
};
